import React from 'react';
import Fade from 'react-reveal/Fade';
import Cables from '../assets/images/laboratorioensayos.png'
import { useTranslation } from "react-i18next";
const TestFiberlab = () => {
    const {t, i18n } = useTranslation();
    return (
        <Fade right>
            <div className="bg-white flex w-full pt-1 lg:pt-0 lg:pl-10 p-10 mt-0 lg:mt-0 bg-gray-100">
                <div className="w-full flex flex-col lg:flex-row">

                    <div className="w-full flex flex-col justify-center pt-20 lg:flex-row">
                        <div className="w-full flex flex-col justify-center lg:flex-col">
                          <div className="flex lg:text-4xl lg:font-bold text-gray-800 lg:self-center md:self-start lg:pb-0 text-2xl font-bold mb-4 font md:text-justify">{t('tt1')} </div>
                          <div className="flex flex-col self-center lg:self-center lg:p-10 lg:text-start text-gray-900 font pt-10 pb-10">
                              <strong className="pb-2 text-justify lg:text-center">{t('tb1')}</strong>
                              <div className="flex flex-col self-start text-start w-full">
                                  <div className="font text-start font-normal w-full text-sm text-justify lg:text-center">{t('tb2')}</div>
                                  <div className="font text-start font-normal w-full text-sm text-justify lg:text-center">{t('tb3')}</div>
                                  <div className="font text-start font-normal w-full text-sm text-justify lg:text-center">{t('tb4')}</div>
                                  <div className="font text-start font-normal w-full text-sm text-justify lg:text-center">{t('tb5')}</div>
                                  <div className="font text-start font-normal w-full text-sm text-justify lg:text-center">{t('tb6')}</div>
                                  <div className="font text-start font-normal w-full text-sm text-justify lg:text-center">{t('tb7')}</div>
                                  <div className="font text-start font-normal w-full text-sm text-justify lg:text-center">{t('tb8')}</div>
                              </div>
                              <strong className="pb-2 pt-2 text-justify lg:text-center">{t('tb9')}</strong>
                              <div className="font text-start font-normal w-full text-sm text-justify lg:text-center">{t('tb10')}</div>
                              <div className="font text-start font-normal w-full text-sm text-justify lg:text-center">{t('tb11')} </div>
                              <strong className="pb-2 pt-2 text-justify lg:text-center">{t('tb12')}</strong>
                              <div className="font text-start font-normal w-full text-sm text-justify lg:text-center">{t('tb13')}</div>
                              <div className="font text-start font-normal w-full text-sm text-justify lg:text-center">{t('tb14')}</div>
                              <div className="font text-start font-normal w-full text-sm text-justify lg:text-center">{t('tb15')}</div>
                              <strong className="pb-2 pt-2 text-justify lg:text-center">{t('tb16')}</strong>
                              <div className="font text-start font-normal w-full text-sm text-justify lg:text-center">{t('tb17')} </div>
                              <strong className="pb-2 text-justify lg:text-center">{t('tb18')}</strong>
                          </div>
                        </div>
                    </div>
                    <div className="lg:visible lg:p-0 mt-0 lg:self-center self-center lg:pt-48 lg:pr-10 lg:mb-28 lg:mt-5">
                        <img src={Cables} alt="" className="lg:ml-20 lg:w-auto lg:pt-2"></img>
                    </div>
                </div>
            </div>
        </Fade>
    );
};

export default TestFiberlab;
