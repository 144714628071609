import React from 'react';
import Imagen from '../assets/images/450.jpg'
import Imagen1 from '../assets/images/banner_principal.jpg'
import Imagen2 from '../assets/images/banner_principal2.jpg'
import Fade from 'react-reveal/Fade';
import Estilos from '../Estilos.css'
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import { useTranslation } from "react-i18next";
const responsive = {
  0: { items: 1 },
  1024: { items: 1 },
}
const Banner = () => {
    const {t, i18n } = useTranslation();
    return (
        <div>
           <div className="mt-5 lg:mt-0">
               <div className="mt-20 lg:mt-10 w-full absolute z-10">
                   <Fade bottom>
                       <div className="w-full flex flex-col justify-center">
                           <div className="lg:text-5xl p-3 lg:pl-2 lg:pr-2 pr-5 pl-5 text-4xl font-bold text-white mb-20 mb-0 font xs:text-3xl">{t("HomeUno")} </div>
                           <div className=" flex lg:flex-row flex-col justify-center w-full pt-5 lg:pt-24">
                               <div className="w-full flex justify-center lg:pl-48 text-4xl lg:text-2xl lg:text-6xl font-bold text-white self-center font xs:text-2xl">{t("HomeDos")}</div>
                               <div className="w-full flex flex-col">
                                   <div className="flex justify-center text-2xl font-bold w-full lg:pl-28 lg:justify-start  text-white lg:text-5xl lg:font-bold font">800 8908 490</div>
                                   <div className="flex justify-center text-xl font-bold w-full lg:pl-28 lg:justify-start text-white lg:text-4xl lg:font-bold lg:pr-24 font">fiberlab.com.mx</div>
                               </div>
                           </div>
                       </div>
                   </Fade>
                 </div>
                  <AliceCarousel responsive={responsive}
                      autoPlayInterval={5000}
                      autoPlayDirection="rtl"
                      autoPlay={true}
                      fadeOutAnimation={true}
                      mouseTrackingEnabled={true}
                      disableAutoPlayOnAction={true}
                      infinite={true}
                      disableButtonsControls={true}
                      disableDotsControls={true}>
                        <img src={Imagen1} className="sliderimg"/>
                        <img src={Imagen2} className="sliderimg"/>
                        <img src={Imagen} className="sliderimg"/>

                  </AliceCarousel>
            </div>
        </div>

    );
};

export default Banner;
