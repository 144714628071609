import React from 'react';
import Fade from 'react-reveal/Fade';
import Cables from '../assets/images/sprittel.PNG'
import { useTranslation } from "react-i18next";
const Splittel = () => {
    const {t, i18n } = useTranslation();
    return (
        <Fade right>
            <div className="bg-white flex w-full  pt-10 lg:pt-10 lg:pl-14 p-10 mt-0 lg:mt-0">
                <div className="w-full flex flex-col lg:flex-row">

                    <div className="w-full  flex flex-col justify-center self-center">
                        <div className="flex lg:text-4xl lg:font-bold text-gray-800 self-center lg:pb-10 text-3xl font-bold lg:mb-0 mb-10 font" id="splittel">Splittel</div>
                        <div className="flex self-center lg:w-96 lg:text-center text-gray-700 font">
                            {t('hb1a')}
                        </div>
                        <a href="https://www.splittel.com" target="_blank" className="know-background text-center text-white w-28 self-center mt-10 rounded-full p-2">{t('hbo1')} </a>
                    </div>
                    <div className=" lg:visible w-full self-center lg:p-0 pt-10">

                            <img src={Cables} alt="" ></img>

                        </div>
                </div>
            </div>
        </Fade>
    );
};

export default Splittel;
