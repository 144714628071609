import React from 'react';
import Certificado_uno from '../assets/images/certificado_uno.PNG'
import Certificado_dos from '../assets/images/certificado_dos.PNG'
const Certificates = () => {
    return (
        <div className="flex h-80 lg:flex-row justify-center self-center flex-col w-full lg:pt-20  ">

                <div className="flex  self-center lg:pr-2 pb-0 h-auto">
                    <img src={Certificado_dos} alt=""/>
                </div>
                <div className="flex self-center pb-0 h-auto">
                <img src={Certificado_uno} alt=""/>
                </div>

        </div>

    );
};

export default Certificates;
