import React from 'react';
import Utilities from '../utilities/MasterHeader'
import WhatDo from '../components/WhatDo'
import Philosophy from '../components/Philosophy'
import Splittel from '../components/Splittel'
import Footer from '../components/Footer'
import HowEmpresas from '../components/How_empresas'
const Blog = () => {
    return (
        <div>
            <Utilities />
            <HowEmpresas />
            <WhatDo />
            <Philosophy />
            <Splittel />
            <Footer />
        </div>
    );
};

export default Blog;