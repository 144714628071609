import React, {useState, useRef} from 'react';
import useInterval from '../hooks/useTimeout.js';
import Cables from '../assets/images/QuienesSomos.png'
import emailjs from 'emailjs-com';
import { useTranslation } from "react-i18next";

const Formulario = () => {
const {t, i18n } = useTranslation();
  const form = useRef();
  const [nombre,setNombre] = useState("")
  const [email,setEmail] = useState("")
  const [company,setCompany] = useState("")
  const [telefono,setTelefono] = useState("")
  const [extencion,setExtencion] = useState("")
  const [mensaje,setMensaje] = useState("")
  const [politica,setPolitica] = useState(false)
  const [error,setError] = useState("")
  const [label, setLabel] = useState("Enviar")
  const [interval, setInterval] = useState(0)
  const [color, setColor] = useState("bg-green-400 items-center hover:bg-green-400  text-center flex lg:justify-center font-bold pr-20")
  const [bg, setBg] = useState("h-screen bg-black flex flex-col justify-center p-60")
  useInterval(() => {
      setColor("p-2 bg-green-500 mt-5 text-white rounded w-40 self-center hover:bg-green-400 ")
      setLabel("Enviar")
      setBg("h-screen bg-black flex flex-col justify-center p-60")
  }, interval);
   const enviar = (e) => {
     e.preventDefault();
     console.log(politica)
     if (!nombre || !email || !company || !telefono || !mensaje || politica == false) {
       setColor("p-2 bg-red-500 mt-5 text-white rounded w-full self-center hover:bg-red-400" )
       setLabel("Revisa el formulario, hay campos vacios")
       setInterval(5000)
     }
     else {
         emailjs.sendForm('service_rsh4o6d', 'template_5rn03hu', form.current, 'user_GEooN9nuwOOGX7CcWK68p')
        .then((result) => {
            window.location = "/"
        }, (error) => {
            console.log(error.text);
        });
     }
     //console.log(nombre.target.value)
   }
    return (
            <div className="flex bg-white w-full h-68  pt-8 lg:pt-0 lg:pl-14 p-10 mt-10 ">
                <div className="w-full flex flex-col lg:flex-row ">
                    <div className="w-full lg:h-auto flex flex-col justify-center self-center ">
                        <div className="flex lg:text-4xl lg:font-bold subtitulo self-center lg:pb-10 text-3xl font-bold mb-10 font pt-10">{t('at2')} </div>
                        <div className=" flex flex-col self-center lg:w-full lg:text-center font  lg:pl-96 lg:pr-96 lg:pb-20 rounded bg-gray-300">
                            <div className="flex flex-col mt-10 sx:pl-28 sx:pr-28">
                                <label className="font text-left pb-2 text-gray-900">{error}</label>
                                <form ref={form} onSubmit={enviar} className="flex flex-col">
                                <label className="font text-left pb-2 text-gray-900 pl-2 lg:pl-0">* {t('ab1')}</label>
                                <input type="text" name="nombre" placeholder="Eje. Juan López" className="p-2 mb-2 font ml-2 mr-2 w-86 lg:w-full lg:ml-0 lg:mr-0" value={nombre} onChange={e => setNombre(e.target.value)} required/>
                                <label className="font text-left pb-2 text-gray-900 pl-2 lg:pl-0">* {t('ab2')}</label>
                                <input type="text" name="email" placeholder="Eje. example@gmail.com"  className="p-2 mb-2 font ml-2 mr-2 w-86 lg:w-full lg:ml-0 lg:mr-0" value={email} onChange={e => setEmail(e.target.value)} required/>
                                <label className="font text-left pb-2 text-gray-900 pl-2 lg:pl-0">* {t('ab3')}</label>
                                <input type="text" name="company" placeholder="Eje. ACME SA DE CV"  className="p-2 mb-2 font ml-2 mr-2 w-86 lg:w-full lg:ml-0 lg:mr-0" value={company} onChange={e => setCompany(e.target.value)} required/>
                                <div className="flex flex-row">
                                  <div className="flex flex-col w-56 pl-2 lg:w-full lg:pl-0">
                                  <label className="font text-left pb-2 text-gray-900">* {t('ab4')}</label>
                                  <input type="text" name="phone" placeholder="Eje. 55-55-55-55"  className="p-2 mb-2 font" value={telefono} onChange={e => setTelefono(e.target.value)} required/>
                                  </div>
                                  <div className="flex flex-col w-32 pr-2 lg:w-full lg:pr-0">
                                  <label className="font text-left pb-2 text-gray-900 pl-2 lg:pl-0">{t('ab5')}</label>
                                  <input type="text" name="extention" placeholder="Eje. 350"  className="ml-2 p-2 mb-2 font" value={extencion} onChange={e => setExtencion(e.target.value) } required/>
                                  </div>
                                </div>
                                <label className="font text-left pb-2 text-gray-900 pl-2 lg:pl-0">* {t('ab6')}</label>
                                <textarea col="5" id="mensaje" name="mensaje" className="textarea ml-2 mr-2 lg:mr-0 lg:ml-0" onChange={e => setMensaje(e.target.value)} value={mensaje} required>
                                </textarea>
                                <div className="flex flex-row mt-5 text-sm pl-2 lg:p-0 lg:text-base">
                                <input type="checkbox" id="aviso" name="aviso"   className="self-center" onChange={e => setPolitica(e.target.checked)}/>
                                <label for="aviso" className="font pl-2 self-center">* {t('ab7')}</label>
                                </div>
                                <button className={color} onClick={enviar}>{label}</button>
                                <div className="font mt-10 text-sm p-2 lg:text-base lg:p-0">{t('ab9')}</div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
};

export default Formulario;
