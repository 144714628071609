import React from 'react';
import Utilities from '../utilities/MasterHeader'
import Banner from '../components/Banner';
import Separador from '../components/Separador'
import Descripcion from '../components/Description'
import Certificates from '../components/Certificates'
import Footer from '../components/Footer'
import Know from '../components/Know';
import How from '../components/How'
import Fortaleza from '../components/nuestras-fortalezas'
const Home = () => {
    return (
        <div>
            <Utilities />
            <Banner/>
            <Descripcion />
            <Certificates />
            <Know />
            <How />
            <Footer />
        </div>
    );
};

export default Home;
