import {BrowserRouter as Router, Route } from 'react-router-dom'
import Home from '../pages/Home'
import Empresas from '../pages/Empresa'
import Acreditaciones from '../pages/Acreditaciones'
import Laboratorio from '../pages/Laboratorio'
import Servicios from '../pages/Servicios'
import Blog from '../pages/Blog'
import Wiki from '../pages/wiki'
import Media from '../pages/Media'
import Contacto from '../pages/contact'
function Application(params) {
    return(
        <Router>
            <Route exact path="/">
                <Home />
            </Route>
            <Route exact path="/empresa">
                <Empresas />
            </Route>
            <Route exact path="/acreditaciones">
                <Acreditaciones />
            </Route>
            <Route exact path="/laboratorio">
                <Laboratorio />
            </Route>
            <Route exact path="/servicios">
                <Servicios />
            </Route>
            <Route exact path="/blog">
                <Blog />
            </Route>
            <Route exact path="/media">
                <Media />
            </Route>
            <Route exact path="/wiki">
                <Wiki />
            </Route>
            <Route exact path="/contacto">
                <Contacto />
            </Route>
        </Router>
    )
}

export default Application;
