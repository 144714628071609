import React from 'react';
import Utilities from '../utilities/MasterHeader'
import Accreditation from '../components/Accreditation_Accreditation_Page'
import Footer from '../components/Footer'
import ISO from '../components/ISO';
import EMA from '../components/EMA';
import AEMA from '../components/Accreditation_ema';
import Certificates from '../components/Certificates'
import Advantage from '../components/Advantage_accreditation';

const Acreditaciones = () => {
    return (
        <div>
            <Utilities />
            <Accreditation />
            <ISO />
            <EMA />
            <Certificates className="bg-red-500" />
            <AEMA />
            <Footer />
        </div>
    );
};

export default Acreditaciones;
